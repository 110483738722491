<!-- 系统消息 -->
<template>
  <div class="system">
    <div class="moban_tou flex align-items space-between">
      <div
        class="fenlei"
        @click="leixing(index)"
        v-for="(item, index) in liebiao"
        :key="index"
        :class="{ act: timuNum == index }"
      >
        {{ item }}
        <div class="sanjiao"></div>
      </div>
      <div class="flex align_items" v-if="fzxt != 1">
        <div class="yidu flex align-items cursor_pointer" @click="qubu">
          <img src="../../../assets/img/xinban/yidu.png" class="icon" alt="" />
          <div class="du">{{ "全部已读" }}</div>
        </div>
        <div
          class="yidu flex align-items cursor_pointer"
          style="margin-left: 10px"
          @click="fzxt = 1"
        >
          <img src="../../../assets/img/xinban/pshan.png" class="icon" alt="" />
          <div class="du">{{ "批量删除" }}</div>
        </div>
      </div>
      <div class="flex align-items" v-else>
        <div class="yidu flex align-items cursor_pointer du">
          <el-checkbox v-model="checked" @change="qubuxuan">全选</el-checkbox>
        </div>
        <div
          class="yidu flex align-items cursor_pointer"
          style="margin-left: 30px"
        >
          <div class="du" @click="quxiaoa">{{ "取消" }}</div>
        </div>
        <div
          class="yidu flex align-items cursor_pointer"
          style="margin-left: 10px"
        >
          <el-button type="primary" @click="dels"
            >删除({{ heji.length }})</el-button
          >
        </div>
      </div>
    </div>
    <el-empty description="" v-if="list.length == 0"></el-empty>
    <!-- 消息 -->
    <div class="biaoshen" v-else>
      <!-- 模板 -->
      <div
        class="flex"
        v-for="(item, index) in list"
        :key="index"
        @mouseover="over(index, item.id, item.is_read)"
      >
        <div class="flex align-items justify-content" style="margin-left: 30px">
          <el-checkbox
            v-model="item.zfx"
            v-if="fzxt != 0"
            @change="xuanyige(index)"
          ></el-checkbox>
        </div>
        <div class="hang flex1">
          <div class="flex align-items">
            <div class="dian" :class="{ opacity: item.is_read == 1 }"></div>
            <div class="fontOne">{{ item.systime }}</div>
          </div>
          <div class="shenhe">
            <!-- 举报帖子/评论操作 -->
            <div v-if="item.type == 5" class="fontTwo">
              {{ guo(item.msg) }}
              <span
                v-if="item.cardsalt != ''"
                class="cursor_pointer fontThree"
                @click="xiangqing(item.type, item.cardsalt)"
                >{{ "查看 >" }}</span
              >
            </div>
            <!-- 处理通过举报评论操作 -->
            <div v-else-if="item.type == 6" class="fontTwo">
              {{ guo(item.msg) }}
            </div>
            <!-- 注册会员 -->
            <div v-else-if="item.type == 7" class="fontTwo">
              {{ guo(item.msg) }}
            </div>
            <!-- 审核帖子 -->
            <div v-else-if="item.type == 8" class="fontTwo">
              <div v-if="item.examstatus_txt == '未通过审核'">
                <span>{{ `网民，您好！您于${item.articletime}发表的` }}</span>
                <span>“</span>
                <span class="lan">{{ item.port }}</span>
                <span class="lan">{{ item.title }}</span>
                <span>”</span>
                <span>{{ item.examstatus_txt }}，</span>
                <span>{{ "原因" }}:</span>
                <span>“{{ item.examreason }}”。</span>
                <span
                  class="cursor_pointer fontThree ju white-space"
                  @click="wei(item.type, item.cardsalt, '1')"
                  >{{ "查看 >" }}</span
                >
              </div>
              <div v-else>
                <span>{{ `网民，您好！您于${item.articletime}发表的` }}</span>
                <span>“</span>
                <span class="lan">{{ item.port }}</span>
                <span class="lan">{{ item.title }}</span>
                <span>”</span>
                <span>{{ item.examstatus_txt }}。</span>
                <span
                  class="cursor_pointer fontThree white-space"
                  @click="xiangqing(item.type, item.cardsalt)"
                  >{{ "查看 >" }}</span
                >
              </div>
            </div>
            <!-- 处理不通过举报帖子/评论操作 -->
            <div v-else-if="item.type == 10" class="fontTwo">
              {{ guo(item.msg) }}
              <span
                v-if="item.cardsalt != ''"
                class="cursor_pointer fontThree white-space"
                @click="xiangqing(item.type, item.cardsalt)"
                >{{ "查看 >" }}</span
              >
            </div>
            <!-- 隐藏帖子 -->
            <div v-else-if="item.type == 12" class="fontTwo">
              <span>{{ `网民，您好！您于${item.articletime}发表的` }}</span>
              <span>“</span>
              <span class="lan">{{ item.port }}</span>
              <span class="lan">{{ item.title }}</span>
              <span>”</span>
              <span>，{{ "根据《网络发言人发贴规则》" }}，</span>
              <span>{{ "我们已经对其做了隐藏处理" }}，</span>
              <span>{{ "原因" }}:</span>
              <span>“{{ item.hide_reason }}”。</span>
              <span
                class="cursor_pointer fontThree ju white-space"
                @click="wei(item.type, item.cardsalt, '0')"
                >{{ "查看 >" }}</span
              >
            </div>
            <!-- 不公开 -->
            <div v-else-if="item.type == 13" class="fontTwo">
              <span>{{ `网民，您好！您于${item.articletime}发表的` }}</span>
              <span>“</span>
              <span class="lan">{{ item.port }}</span>
              <span class="lan">{{ item.title }}</span>
              <span>”</span>
              <span>，{{ "根据《网络发言人发贴规则》" }}，</span>
              <span>{{ "我们已经对其做了不公开处理" }}。</span>
              <span
                class="cursor_pointer fontThree ju white-space"
                @click="wei(item.type, item.cardsalt, '0')"
                >{{ "查看 >" }}</span
              >
            </div>
            <!-- 审核评论 -->
            <div v-else-if="item.type == 14" class="fontTwo">
              <div v-if="item.examstatus_txt == '未通过审核'">
                {{ `网民，您好！您于${item.articletime}发表的评论未通过审核!` }}
                <!-- <span class="cursor_pointer fontThree white-space"
                                @click="xiangqing(item.type, item.cardsalt)">{{ '查看 >'
                                }}</span> -->
              </div>
              <div v-else>
                {{ `网民，您好！您于${item.articletime}发表的评论已通过审核!` }}
                <span
                  class="cursor_pointer fontThree white-space"
                  @click="xiangqing(item.type, item.cardsalt)"
                  >{{ "查看 >" }}</span
                >
              </div>
            </div>
            <!-- 申请删除提示 -->
            <div v-else-if="item.type == 15" class="fontTwo">
              <div>
                {{ item.msg }}
                <span
                  v-if="item.cardsalt != ''"
                  class="cursor_pointer fontThree white-space"
                  @click="xiangqing(item.type, item.cardsalt)"
                  >{{ "查看 >" }}</span
                >
              </div>
            </div>
            <!-- 其他 -->
            <div v-else class="fontTwo">
              {{ guo(item.msg) }}
              <span
                v-if="item.cardsalt != ''"
                class="cursor_pointer fontThree white-space"
                @click="xiangqing(item.type, item.cardsalt)"
                >{{ "查看 >" }}</span
              >
            </div>
          </div>
        </div>
        <!-- <div v-if="item.examstatus_txt != '未通过审核'">
                    <div class="flex align-items">
                        <div class="dian" :class="{ opacity: item.is_read == 1 }"></div>
                        <div class="fontOne">{{ item.systime }}</div>
                    </div>
                    <div class="shenhe">
                        <div v-if="item.type == 14" class="fontTwo">{{ `网民，您好！您于${item.articletime}发表的评论已通过审核!` }}
                            <span v-if="item.cardsalt != ''" class="cursor_pointer fontThree"
                                @click="xiangqing(item.type, item.cardsalt)">{{ '查看 >' }}</span>
                        </div>
                        <div v-else-if="item.type == 12" class="fontTwo">
                            <span>{{ guo(item.msg) }}</span>
                            <span class="lan">{{ '隐藏的原因:' }}</span>
                            <span class="lan">{{ item.hide_reason }}</span>
                            <span v-if="item.cardsalt != ''" class="cursor_pointer fontThree ju"
                                @click="wei(item.type, item.cardsalt,'0')">{{ '查看 >' }}</span>
                        </div>
                        <div v-else class="fontTwo">{{ guo(item.msg) }}
                            <span v-if="item.cardsalt != ''" class="cursor_pointer fontThree"
                                @click="xiangqing(item.type, item.cardsalt)">{{ '查看 >' }}</span>
                        </div>
                    </div>
                </div> -->
        <!-- <div v-else>
                    <div class="flex align-items">
                        <div class="dian" :class="{ opacity: item.is_read == 1 }"></div>
                        <div class="fontOne">{{ item.systime }}</div>
                    </div>
                    <div class="shenhe">
                        <div class="fontTwo">
                            <span>{{ guo(item.msg) }}</span>
                            <span>{{ item.examstatus_txt }}</span>
                            <span class="lan">{{ '原因:' }}</span>
                            <span class="lan">{{ item.examreason }}</span>
                            <span v-if="item.cardsalt != ''" class="cursor_pointer fontThree ju"
                                @click="wei(item.type, item.cardsalt, '1')">{{ '查看 >'
                                }}</span>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/message";
import Bus from "@/assets/ligature";
export default {
  data() {
    return {
      list: [],
      page: 1,
      zongye: 10,
      liebiao: ["系统消息"],
      timuNum: 0,
      fzxt: 0, //开启删除形态
      checked: false, //正反选
      heji: [], //所有被选中的数组
    };
  },
  mounted() {
    this.sysMessage();
  },
  watch: {},
  methods: {
    // 确认删除弹框
    dels() {
      if (this.heji.length == 0) {
        return;
      }
      this.$confirm("是否删除选中的消息", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.delMessage({
            id: this.heji.toString(),
            type: 3,
          }).then((ress) => {
            if (ress.code == 1) {
              this.page = 1;
              this.sysMessage();
              // 搜索关键字:消息刷新
              Bus.$emit("xioaxi");
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: ress.msg,
              });
            }
            this.quxiaoa();
          });
        })
        .catch(() => {});
    },
    // 取消
    quxiaoa() {
      this.fzxt = 0;
      this.heji = [];
      this.checked = false;
      this.list.forEach((item) => {
        item.zfx = false;
      });
      let a = this.list;
      this.list = [];
      this.list = a;
    },
    // 全选
    qubuxuan() {
      if (this.checked == true) {
        let b;
        this.list.forEach((item) => {
          item.zfx = true;
          b = this.heji.indexOf(item.id);
          if (b == -1) {
            this.heji.push(item.id);
          }
        });
      } else {
        this.list.forEach((item) => {
          this.heji.forEach((res, index) => {
            if (item.id == res) {
              item.zfx = false;
              this.heji.splice(index, 1);
            }
          });
        });
      }
      let a = this.list;
      this.list = [];
      this.list = a;
    },
    // 正反选,选一个
    xuanyige(num) {
      // 这里为什么要等于true,因为判断前,组件已将将值改为true了
      if (this.list[num].zfx == true) {
        // this.list[num].zfx = true;
        this.heji.push(this.list[num].id);
      } else {
        // this.list[num].zfx = false;
        let b = this.heji.indexOf(this.list[num].id);
        this.heji.splice(b, 1);
      }
      // let a = this.list;
      // this.list = [];
      // this.list = a;
      this.pdm();
    },
    pdm() {
      let a = true;
      this.list.forEach((item) => {
        if (a == true) {
          if (item.zfx == true) {
            a = true;
          }
        }
        if (item.zfx == false) {
          a = false;
        }
      });
      // a为true代表装载对的数据全部为选中
      if (a == true) {
        this.checked = true;
      } else {
        // 这里只能单纯的把全选取消,不能清空数组
        this.checked = false;
      }
    },
    // 审核不通过
    tutongup(str) {
      this.$alert(str, "该贴尚未通过审核,未通过原因为:", {
        confirmButtonText: "确定",
        // callback: action => {

        // }
      });
    },
    // 过略
    guo(str) {
      str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
      str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
      return str;
    },
    // 单个已读
    async over(num, wid, read) {
      if (read == 1) {
        return;
      }
      let res = await API.readMessageone({
        id: wid,
        type: 3,
      }).then((res) => {
        if (res.code == 1) {
          this.list[num].is_read = 1;
          // 搜索关键字:消息刷新
          Bus.$emit("xioaxi");
        }
      });
    },
    // 全部已读
    async qubu() {
      let res = await API.readMessage({
        type: 3,
      }).then((res) => {
        if (res.code == 1) {
          // 搜索关键字:消息刷新
          this.list.forEach((item) => {
            item.is_read = 1;
          });
          Bus.$emit("xioaxi");
        }
      });
    },
    // 跳转至为通过审核和隐藏的帖子详情
    wei(type, id, num) {
      this.$store.state.wt = num;
      this.$store.state.loginMessage.cardsalt = id;
      // this.jump("notpass", this.$store.state.loginMessage);
      // 策略如果不允许评论则跳入detail,现在没有隐藏的帖子
      this.jump("details", this.$store.state.loginMessage);
    },
    // 跳转至详情
    xiangqing(type, id, num) {
      this.$store.state.wt = num;
      // type:7系统欢迎信息
      if (type == "7") {
        return;
      }
      // type:10未通过审核
      if (type == "10") {
        return;
      }
      this.$store.state.loginMessage.cardsalt = id;
      this.jump("details", this.$store.state.loginMessage);
    },
    async sysMessage() {
      let res = await API.sysMessage({
        type: "",
        page: this.page,
        pageLine: this.zongye,
      }).then((res) => {
        if (res.code == 1) {
          let a = res.data.list;
          a.forEach((item) => {
            item["zfx"] = false;
          });
          this.list = a;
          this.fanye();
          this.$emit("yema", res.data.total);
          this.$emit("sys", res.data.weicount);
          this.$parent.yema(res.data.total);
        }
      });
    },
    // 翻页的时候触发
    fanye() {
      // 没有选中时不需要触发
      if (this.heji.length == 0) {
        // console.log("111----");
        return;
      }
      // console.log("111----22222");
      // 这里要把已选择的要点亮,不能因为翻页取消掉
      this.list.forEach((item) => {
        this.heji.forEach((res) => {
          if (item.id == res) {
            item.zfx = true;
          }
        });
      });

      let a = true;
      this.list.forEach((item) => {
        if (a == true) {
          if (item.zfx == true) {
            a = true;
          }
        }
        if (item.zfx == false) {
          a = false;
        }
      });
      // a为true代表装载对的数据全部为选中
      if (a == true) {
        this.checked = true;
      } else {
        // 这里只能单纯的把全选取消,不能清空数组
        this.checked = false;
      }
      // 需要重新渲染
      let b = this.list;
      this.list = [];
      this.list = b;
    },
    leixing(num) {
      this.timuNum = num;
      this.sysMessage();
    },
    tioamu(num) {
      this.zongye = num;
      this.sysMessage();
    },
    ymu(num) {
      this.page = num;
      this.sysMessage();
    },
  },
};
</script>

<style scoped lang="scss">
.system {
  width: 100%;
}

.biaotou {
  height: 56px;
  display: flex;
  align-items: center;
}

.btn {
  width: 96px;
  height: 56px;
  background: #2670c5;
  line-height: 56px;
  text-align: center;
  font-family: PingFangSC-Medium;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  position: relative;

  .sanjiao {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 5px;
    left: calc(50% - 5px);
    background: transparent;
    border-style: solid;
    border-width: 0 10px 5px 10px;
    border-color: transparent transparent #fff transparent;
  }
}

.biaoshen {
  .hang {
    // height: 87px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 42px 10px 12px;
    box-sizing: border-box;
    border-bottom: 1px dashed transparent;
    background: linear-gradient(#fff, #fff) padding-box,
      repeating-linear-gradient(
        -45deg,
        #d6dbe3 0,
        #d6dbe3 0.3em,
        #fff 0,
        #fff 0.6em
      );

    /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
    .dian {
      width: 6px;
      height: 6px;
      margin-right: 15px;
      background: #f11515;
      border-radius: 50%;
    }

    .fontOne {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #484c51;
      letter-spacing: 0;
      text-align: justify;
      line-height: 21px;
      font-weight: 400;
    }

    .shenhe {
      margin-top: 10px;
      margin-left: 21px;

      .fontTwo {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #191a1c;
        letter-spacing: 0;
        font-weight: 400;
        margin-right: 10px;
        float: left;
      }

      .fontThree {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #2670c5;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}

.moban_tou {
  height: 61px;
  padding: 0 20px 0 0;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 30px 0 30px;
    line-height: 61px;
    position: relative;
    cursor: pointer;

    .sanjiao {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 5px;
      left: calc(50% - 5px);
      background: transparent;
      border-style: solid;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.act {
  background: #2670c5 !important;
  font-size: 15px !important;
  color: #ffffff !important;
}

.yidu {
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.lan {
  color: #043e81;
}

.ju {
  margin-left: 10px;
}
</style>