<!-- 主题消息 -->
<template>
  <div class="system">
    <div class="moban_tou flex align-items space-between">
      <div class="flex">
        <div
          class="fenlei"
          @click="leixing(index)"
          v-for="(item, index) in liebiao"
          :key="index"
          :class="{ act: timuNum == index }"
        >
          <el-badge :value="item.num" class="item" :hidden="item.num == 0">
            <div class="na">{{ item.name }}</div>
          </el-badge>
          <div class="sanjiao"></div>
        </div>
      </div>
      <!-- <div class="yidu flex align-items cursor_pointer" @click="qubu">
        <img src="../../../assets/img/xinban/yidu.png" class="icon" alt="" />
        <div class="du">{{ "全部已读" }}</div>
      </div> -->
      <div class="flex align_items" v-if="fzxt != 1">
        <div class="yidu flex align-items cursor_pointer" @click="qubu">
          <img src="../../../assets/img/xinban/yidu.png" class="icon" alt="" />
          <div class="du">{{ "全部已读" }}</div>
        </div>
        <div
          class="yidu flex align-items cursor_pointer"
          style="margin-left: 10px"
          @click="fzxt = 1"
        >
          <img src="../../../assets/img/xinban/pshan.png" class="icon" alt="" />
          <div class="du">{{ "批量删除" }}</div>
        </div>
      </div>
      <div class="flex align-items" v-else>
        <div class="yidu flex align-items cursor_pointer du">
          <el-checkbox v-model="checked" @change="qubuxuan">全选</el-checkbox>
        </div>
        <div
          class="yidu flex align-items cursor_pointer"
          style="margin-left: 30px"
        >
          <div class="du" @click="quxiaoa">{{ "取消" }}</div>
        </div>
        <div
          class="yidu flex align-items cursor_pointer"
          style="margin-left: 10px"
        >
          <el-button type="primary" @click="dels"
            >删除({{ heji.length }})</el-button
          >
        </div>
      </div>
    </div>
    <!-- 消息  -->
    <div class="biaoshen">
      <!-- 模板 赞的信息 -->
      <div v-if="timuNum == 0">
        <el-empty description="" v-if="list.length == 0"></el-empty>
        <div v-else>
          <div
            class="flex"
            v-for="(item, index) in list"
            :key="index"
            @mouseover.stop="over(index, item.id, item.is_read)"
          >
            <div
              class="flex align-items justify-content"
              style="margin-left: 30px"
            >
              <el-checkbox
                v-model="item.zfx"
                v-if="fzxt != 0"
                @change="xuanyige(index)"
              ></el-checkbox>
            </div>

            <div class="hang">
              <div class="flex align-items">
                <div class="dian" :class="{ opacity: item.is_read == 1 }"></div>
                <div class="fontOne">{{ item.handle_time }}</div>
              </div>
              <div class="flex shenhe">
                <div class="fontTwo flex">
                  <div class="taren" @click="taren(item.handle_id)">
                    {{ item.usename }}
                  </div>
                  <div>{{ "点赞了您的" }}</div>
                  <div>{{ item.kind_txt }}</div>
                </div>
                <div
                  class="cursor_pointer fontThree"
                  @click="xiangqing(item.cardsaltpid)"
                >
                  {{ "查看原文 >" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 模板 收到的回复 -->
      <div v-if="timuNum == 1">
        <el-empty description="" v-if="list.length == 0"></el-empty>
        <div v-else>
          <div
            class="flex"
            v-for="(item, index) in list"
            :key="index"
            @mouseover.stop="over(index, item.id, item.is_read)"
          >
            <div
              class="flex align-items justify-content"
              style="margin-left: 30px"
            >
              <el-checkbox
                v-model="item.zfx"
                v-if="fzxt != 0"
                @change="xuanyige(index)"
              ></el-checkbox>
            </div>
            <div class="hang">
              <div class="flex align-items">
                <div class="dian" :class="{ opacity: item.is_read == 1 }"></div>
                <div class="fontOne">{{ item.comment_time }}</div>
              </div>
              <div class="flex shenhe">
                <div class="fontTwo flex align-items">
                  <div class="taren" @click="taren(item.handle_id)">
                    {{ item.usename }}
                  </div>
                  <div>{{ "回复了您:" }}</div>
                  <div class="neiron">{{ guolue(item.content) }}</div>
                </div>
                <div
                  class="cursor_pointer fontThree"
                  @click="xiangqing(item.cardsaltpid)"
                >
                  {{ "查看原文 >" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 模板 被关注 -->
      <div v-if="timuNum == 2">
        <el-empty description="" v-if="list.length == 0"></el-empty>
        <div v-else>
          <div
            class="flex"
            v-for="(item, index) in list"
            :key="index"
            @mouseover.stop="over(index, item.id, item.is_read)"
          >
            <div
              class="flex align-items justify-content"
              style="margin-left: 30px"
            >
              <el-checkbox
                v-model="item.zfx"
                v-if="fzxt != 0"
                @change="xuanyige(index)"
              ></el-checkbox>
            </div>
            <div class="hang">
              <div class="flex align-items">
                <div class="dian" :class="{ opacity: item.is_read == 1 }"></div>
                <div class="fontOne">{{ item.followtime }}</div>
              </div>
              <div class="flex shenhe">
                <div class="fontTwo flex align-items">
                  <div class="taren" @click="taren(item.handle_id)">
                    {{ item.usename }}
                  </div>
                  <div>{{ "关注了您!" }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/message";
import Bus from "@/assets/ligature";
export default {
  data() {
    return {
      list: [],
      page: 1,
      zongye: 10,
      liebiao: [
        {
          name: "收到的赞",
          num: 0,
        },
        {
          name: "收到的回复",
          num: 0,
        },
        {
          name: "粉丝",
          num: 0,
        },
      ],
      timuNum: 0,
      dangqian: 0,
      fzxt: 0, //开启删除形态
      checked: false, //正反选
      heji: [], //所有被选中的数组
    };
  },
  mounted() {
    this.liebiao[0].num = this.$store.state.sz;
    this.liebiao[1].num = this.$store.state.sf;
    this.liebiao[2].num = this.$store.state.fs;
    this.themeMessage(this.dangqian);
  },
  watch: {
    "$store.state.sz": function (vla, old) {
      this.liebiao[0].num = vla;
    },
    "$store.state.sf": function (vla, old) {
      this.liebiao[1].num = vla;
    },
    "$store.state.fs": function (vla, old) {
      this.liebiao[2].num = vla;
    },
  },
  methods: {
    // 确认删除弹框
    dels() {
      if (this.heji.length == 0) {
        return;
      }
      this.$confirm("是否删除选中的消息", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.delMessage({
            id: this.heji.toString(),
            type: this.dangqian,
          }).then((ress) => {
            if (ress.code == 1) {
              // 搜索关键字:消息刷新
              Bus.$emit("xioaxi");
              this.page = 1;
              this.themeMessage(this.dangqian);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: ress.msg,
              });
            }
            this.quxiaoa();
          });
        })
        .catch(() => {});
    },
    // 取消
    quxiaoa() {
      this.fzxt = 0;
      this.heji = [];
      this.checked = false;
      this.list.forEach((item) => {
        item.zfx = false;
      });
      let a = this.list;
      this.list = [];
      this.list = a;
    },
    // 全选
    qubuxuan() {
      if (this.checked == true) {
        let b;
        this.list.forEach((item) => {
          item.zfx = true;
          b = this.heji.indexOf(item.id);
          if (b == -1) {
            this.heji.push(item.id);
          }
        });
      } else {
        this.list.forEach((item) => {
          this.heji.forEach((res, index) => {
            if (item.id == res) {
              item.zfx = false;
              this.heji.splice(index, 1);
            }
          });
        });
      }
      let a = this.list;
      this.list = [];
      this.list = a;
    },
    // 正反选,选一个
    xuanyige(num) {
      // 这里为什么要等于true,因为判断前,组件已将将值改为true了
      if (this.list[num].zfx == true) {
        // this.list[num].zfx = true;
        this.heji.push(this.list[num].id);
      } else {
        // this.list[num].zfx = false;
        let b = this.heji.indexOf(this.list[num].id);
        this.heji.splice(b, 1);
      }
      // let a = this.list;
      // this.list = [];
      // this.list = a;
      this.pdm();
    },
    pdm() {
      let a = true;
      this.list.forEach((item) => {
        if (a == true) {
          if (item.zfx == true) {
            a = true;
          }
        }
        if (item.zfx == false) {
          a = false;
        }
      });
      // a为true代表装载对的数据全部为选中
      if (a == true) {
        this.checked = true;
      } else {
        // 这里只能单纯的把全选取消,不能清空数组
        this.checked = false;
      }
    },
    // 单个已读
    async over(num, wid, read) {
      if (read == 1) {
        return;
      }
      let res = await API.readMessageone({
        id: wid,
        type: this.timuNum,
      }).then((res) => {
        if (res.code == 1) {
          this.list[num].is_read = 1;
          // 搜索关键字:消息刷新
          Bus.$emit("xioaxi");
        }
      });
    },
    // 全部已读
    async qubu() {
      let res = await API.readMessage({
        type: this.timuNum,
      }).then((res) => {
        if (res.code == 1) {
          // 搜索关键字:消息刷新
          Bus.$emit("xioaxi");
          this.list.forEach((item) => {
            item.is_read = 1;
          });
        }
      });
    },
    guolue(str) {
      // var reg = /<[^>]+>/gi;  //过滤所有的html标签
      var reg = new RegExp("<[^>]+>", "gi"); //过滤所有的html标签，不包括内容
      let a = str.replace(reg, "");
      if (a.length > 30) {
        return a.slice(0, 20) + "...";
      }
      return a;
    },
    // 他人信息
    taren(id) {
      this.$store.state.loginMessage.id = id;
      if (id == localStorage.getItem("uid")) {
        this.jumps("persomal", this.$store.state.loginMessage);
      } else {
        this.jump("other", this.$store.state.loginMessage);
      }
    },
    // 跳转至详情
    async xiangqing(id) {
      let a = true;
      let res = await API.articleInfo({
        cardid: id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          a = false;
        }
      });
      if (a == false) {
        return;
      }
      this.$store.state.loginMessage.cardsalt = id;
      this.jump("details", this.$store.state.loginMessage);
    },
    async themeMessage(num) {
      this.dangqian = num;
      let res = await API.themeMessage({
        type: num,
        page: this.page,
        pageLine: this.zongye,
      }).then((res) => {
        if (res.code == 1) {
          let a = res.data.list;
          a.forEach((item) => {
            item["zfx"] = false;
          });
          this.list = a;
          this.$parent.yema(res.data.total);
          // this.$emit("yema", res.data.total);
          this.fanye();
        }
      });
    },
    // 翻页的时候触发
    fanye() {
      // 没有选中时不需要触发
      if (this.heji.length == 0) {
        return;
      }
      // console.log("111----22222");
      // 这里要把已选择的要点亮,不能因为翻页取消掉
      this.list.forEach((item) => {
        this.heji.forEach((res) => {
          if (item.id == res) {
            item.zfx = true;
          }
        });
      });

      let a = true;
      this.list.forEach((item) => {
        if (a == true) {
          if (item.zfx == true) {
            a = true;
          }
        }
        if (item.zfx == false) {
          a = false;
        }
      });
      // a为true代表装载对的数据全部为选中
      if (a == true) {
        this.checked = true;
      } else {
        // 这里只能单纯的把全选取消,不能清空数组
        this.checked = false;
      }
      // 需要重新渲染
      let b = this.list;
      this.list = [];
      this.list = b;
    },
    leixing(num) {
      this.list = [];
      this.timuNum = num;
      this.themeMessage(num);
      this.quxiaoa();
    },
    tioamu(num) {
      this.zongye = num;
      this.themeMessage(this.dangqian);
    },
    ymu(num) {
      this.page = num;
      this.themeMessage(this.dangqian);
    },
  },
};
</script>

<style scoped lang="scss">
.system {
  width: 100%;
}

.biaotou {
  height: 56px;
  display: flex;
  align-items: center;
}

.btn {
  width: 96px;
  height: 56px;
  background: #2670c5;
  line-height: 56px;
  text-align: center;
  font-family: PingFangSC-Medium;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  position: relative;

  .sanjiao {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 5px;
    left: calc(50% - 5px);
    background: transparent;
    border-style: solid;
    border-width: 0 10px 5px 10px;
    border-color: transparent transparent #fff transparent;
  }
}

.biaoshen {
  .hang {
    height: 87px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 42px 0px 12px;
    border-bottom: 1px dashed transparent;
    background: linear-gradient(#fff, #fff) padding-box,
      repeating-linear-gradient(
        -45deg,
        #d6dbe3 0,
        #d6dbe3 0.3em,
        #fff 0,
        #fff 0.6em
      );

    /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
    .dian {
      width: 6px;
      height: 6px;
      margin-right: 15px;
      background: #f11515;
      border-radius: 50%;
    }

    .fontOne {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #484c51;
      letter-spacing: 0;
      text-align: justify;
      line-height: 21px;
      font-weight: 400;
    }

    .shenhe {
      margin-top: 10px;
      margin-left: 21px;

      .fontTwo {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #191a1c;
        letter-spacing: 0;
        font-weight: 400;
        margin-right: 10px;
      }

      .fontThree {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #2670c5;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}

.moban_tou {
  height: 61px;
  padding: 0 20px 0 0;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 30px 0 30px;
    line-height: 61px;
    position: relative;
    cursor: pointer;

    .sanjiao {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 5px;
      left: calc(50% - 5px);
      background: transparent;
      border-style: solid;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.act {
  background: #2670c5 !important;
  font-size: 15px !important;
  color: #ffffff !important;
}

.taren {
  color: #2670c5 !important;
  cursor: pointer;
}

.neiron {
  margin-left: 5px;
  font-weight: 700;
  max-width: 500px;
}

.yidu {
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

:deep(.el-badge__content.is-fixed) {
  top: 18px;
}
</style>