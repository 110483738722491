<!-- 消息中心 -->
<template>
  <div class="_message">
    <!-- 导航标签 -->
    <div class="flex biao align-items">
      <!-- <div>{{ '当前位置 : ' }}</div>
            <div style="opacity: 0;">{{ '0' }}</div>
            <div class="">{{ '首页' }}</div>
            <div class="zuojian">
                <img src="../../assets/img/xinban/zuojian.png" class="w-full h-full" alt="">
            </div>
            <div class="yanse">{{ '消息中心' }}</div> -->
    </div>
    <!-- 发布主题与撤回 -->
    <div class="chehui flex space-between align-items">
      <div class="flex">
        <el-button type="primary" @click="fabu">发表新帖</el-button>
      </div>
      <div class="flex align-items che cursor_pointer" @click="fanhui">
        <img src="../../assets/img/xinban/fanhui.png" class="huitui" alt="" />
        <div>{{ "返回列表" }}</div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="mess w-full flex">
      <div class="zuo">
        <div class="biaoti">
          <img src="../../assets/img/xinban/mess.png" class="icon" alt="" />
          <div>{{ "消息中心" }}</div>
        </div>
        <div
          class="btn flex"
          @click="qiehuan(index)"
          v-for="(item, index) in liebiao"
          :key="index"
          :class="{ act: index == timuNum }"
        >
          <div>{{ item.name }}</div>
          <div class="weidu" v-if="item.weidu != 0">{{ item.weidu }}</div>
        </div>
      </div>
      <div class="you">
        <system v-if="timuNum == 0" ref="system"></system>
        <zhuti v-else-if="timuNum == 1" ref="zhuti"></zhuti>
      </div>
    </div>
    <!-- 页码 -->
    <div class="yema">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :page-sizes="fen"
        :page-size="100"
        layout=" prev, pager, next, sizes, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import system from "./system/index.vue"; //系统消息
import zhuti from "./zhuti/index.vue"; // 主题消息
import API from "@/api/modules/message";
import Cookies from "js-cookie";
export default {
  components: {
    system,
    zhuti,
  },
  data() {
    return {
      liebiao: [
        {
          name: "系统消息",
          weidu: 0,
        },
        {
          name: "主题消息",
          weidu: 0,
        },
      ],
      timuNum: 0,
      page: 1,
      zongye: 10,
      total: 0,
      fen: [0, 10],
    };
  },
  watch: {
    "$store.state.sys": function (vla, old) {
      this.liebiao[0].weidu = vla;
    },
    "$store.state.zhut": function (vla, old) {
      this.liebiao[1].weidu = vla;
    },
  },
  mounted() {
    localStorage.setItem("path", this.$route.path);
    // 先判定是否有token 如果为空
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.$router.push("/login");
      return;
    }
    this.liebiao[0].weidu = this.$store.state.sys;
    this.liebiao[1].weidu = this.$store.state.zhut;
    this.total = 0;
    this.page = 1;
    this.zongye = 10;
    this.fen = [10, 10];
    let timer = setTimeout(() => {
      this.fen = [10, 20];
    }, 500);
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
  },
  methods: {
    // 主题未读
    zhut(num) {
      this.liebiao[1].weidu = num;
    },
    // 系统为读
    sys(num) {
      this.liebiao[0].weidu = num;
    },
    // 进入发布页面
    fabu() {
      this.jumps("issue", this.$store.state.loginMessage);
    },
    // 返回首页
    fanhui() {
      this.jump("/", this.$store.state.loginMessage);
    },
    yema(num) {
      // console.log(num,'数据');
      this.total = num;
    },
    // 切换列表
    qiehuan(num) {
      this.total = 0;
      this.page = 1;
      this.zongye = 10;
      this.fen = [10, 10];
      this.timuNum = num;
      let timer = setTimeout(() => {
        this.fen = [10, 20];
      }, 500);
    },
    // 切换条目
    handleSizeChange(val) {
      if (this.timuNum == 0) {
        this.$refs.system.tioamu(val);
      } else if (this.timuNum == 1) {
        this.$refs.zhuti.tioamu(val);
      }
      // console.log(`每页 ${val} 条`);
    },
    // 切换页码
    handleCurrentChange(val) {
      if (this.timuNum == 0) {
        this.$refs.system.ymu(val);
      } else if (this.timuNum == 1) {
        this.$refs.zhuti.ymu(val);
      }
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped lang="scss">
._message {
  width: 1200px;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 22px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

.chehui {
  ::v-deep(.el-button--primary) {
    width: 110px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    border: 0px solid red;
    margin-right: 17px;
  }

  ::v-deep(.el-button--info) {
    width: 82px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
  }

  .che {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2670c5;
    letter-spacing: 0;
    font-weight: 400;

    .huitui {
      width: 15.69px;
      height: 13.07px;
      margin-right: 6.15px;
    }
  }
}

.mess {
  background: #ffffff;
  border: 1px solid #d6dbe3;
  min-height: 890px;
  margin-top: 15px;

  .zuo {
    width: 226px;
    border-right: 1px solid #d6dbe3;

    .biaoti {
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #0653ac;
      letter-spacing: 0;
      font-weight: 500;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }

    .btn {
      width: 100%;
      height: 52px;
      cursor: pointer;
      text-align: center;
      line-height: 52px;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #36383a;
      letter-spacing: 0;
      font-weight: 400;
      justify-content: center;
      align-items: center;
      position: relative;
      .weidu {
        font-size: 16px;
        position: absolute;
        top: calc(50% - 11.5px);
        right: 48px;
        height: 23px;
        line-height: 23px;
        padding: 0px 3px;
        background: #f97e58;
        color: #fff;
        // font-weight: 700;
        border-radius: 4px;
      }
    }
  }

  .you {
    flex: 1;
  }
}

.act {
  background: #e8effa !important;
}

.yema {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
}
</style>