// 系统通知
import request from '../request'

export default class Message {

    /**
* 系统消息
* @param {*} params
* @returns
*/
    static sysMessage(params) {
        return request.get('/api/v1/messagecenter/sysMessage', { params })
    }
    /**
* 主题消息
* @param {*} params
* @returns
*/
    static themeMessage(params) {
        return request.get('/api/v1.messagecenter/themeMessage', { params })
    }
    /**
* 全部已读
* @param {*} params
* @returns
*/
    static readMessage(params) {
        return request.get('/api/v1/messagecenter/readMessage', { params })
    }
    /**
* 单个已读消息
* @param {*} params
* @returns
*/
    static readMessageone(params) {
        return request.get('/api/v1/messagecenter/readMessageone', { params })
    }
    /**
* 帖子详情
* @param {*} params 
* @returns 
*/
    static articleInfo(params) {
        return request.get("/api/v1/articlecurrency/articleInfo", {
            params
        })
    }
    /**
     * 删除消息 
     * @param {*} params
     * @returns
     */
    static delMessage(params) {
        return request.get('/api/v1/Messagecenter/delMessage', {
            params
        })
    }
}